.privacy-policy {
  padding-inline: 30px;
  margin: 10px auto;
  h1 {
    font-size: 30px !important;
    word-break: break-word;
    font-weight: 600 !important;
    margin: 10px 0 10px -5px !important;
    letter-spacing: 1px;
    color: #295fa6;
  }
  h2 {
    margin: 8px 0 !important;

    font-size: 24px;
    color: #272727;
    font-weight: 500;
  }
  h3 {
    color: #272727;
  }

  p {
    opacity: 0.7;
    margin-bottom: 20px;
    a {
      color: black;
      text-decoration: underline;
    }
  }
}
