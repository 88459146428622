.blog-container {
  padding-inline: 30px;
  margin: 10px auto;
  h1 {
    font-size: 48px !important;
    word-break: break-word;
    font-weight: 600 !important;
    margin-left: -5px !important;
    letter-spacing: 1px;
    color: #295fa6;
  }
  h2 {
    color: #61b0c1;
  }
  h3 {
    color: #272727;
  }
  .blog-content {
    p,
    li {
      color: #44475b;
    }
    figure {
      display: flex;
      justify-content: center;
    }
    img {
      height: 50vh !important;
      width: 40vw !important;
      display: flex;
    }
  }
  .blog-img {
    width: 100%;
    border-radius: 5px;
    height: 375px;
  }
}
