.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  z-index: 999;
  svg {
    position: fixed;
    top: 8%;
    font-size: 40px;
    right: 10%;
    cursor: pointer;
    color: white;
    z-index: 999;

    @media (max-width: 673px) {
      top: 2.13%;
      font-size: 30px;
      right: 7%;
    }
    @media (max-width: 400px) {
      top: 2.5%;
      font-size: 28px;
      right: 12%;
    }
  }
  .iframe-div {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    iframe {
      width: 85%;
      height: 90%;
      border: none;
      border-radius: 5px;
      @media (max-width: 673px) {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 0px;
      }
    }
  }
}
.web-cards-in-full-page {
  width: calc(32% - 3px) !important;
  margin-bottom: 8px !important;
  position: relative !important;
  overflow: hidden !important;

  @media (max-width: 1024px) {
    width: calc(49% - 2px) !important;
    text-align: center !important;
  }
  @media (max-width: 476px) {
    width: calc(100%) !important;
    text-align: center !important;
  }
}
.web-stories-cards {
  width: calc(24% - 4px);
  margin-bottom: 8px;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    width: calc(49% - 2px);
    text-align: center;
  }
  @media (max-width: 476px) {
    width: calc(100%);
    text-align: center;
  }

  img {
    height: 100%;
    width: 100%;
  }

  .web-stories-detail {
    position: absolute;
    color: white;
    bottom: 3%;
    width: 80%;
    left: 50%;
    text-align: center;
    transform: translate(-52.5%, 0%);
    animation: slideUp 1s;
    padding: 8px 0px 8px 0px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
      rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
      rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset,
      rgba(0, 0, 0, 0.06) 0px 2px 1px;

    @media only screen and (min-width: 674px) and (max-width: 1220px) {
      transform: translate(-50%, 0%);

      @keyframes slideUp {
        0% {
          transform: translate(50%, 140%);
          opacity: 0;
        }

        100% {
          transform: translate(50%, 0%);
          opacity: 1;
        }
      }
    }

    @keyframes slideUp {
      0% {
        transform: translate(-52.5%, 140%);
        opacity: 0;
      }

      100% {
        transform: translate(-52.5%, 0%);
        opacity: 1;
      }
    }
    .story-title {
      word-break: break-word;
      margin: 0px !important;
      font-size: 24px;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      opacity: 0.85;
    }

    .story-time {
      font-size: 15px;
      opacity: 0.8;
      margin: 5px auto 12px auto !important;
    }
  }
}

.web-story-homepage {
  background-color: white;
  padding: 12px;
  border-radius: 8px;
  .web-story-headline-div {
    margin: 8px;
    h2 {
      font-size: 22px;
      font-weight: 700;
    }
    p {
      font-size: 14px;
      opacity: 0.7;
    }
  }
}
.web-stories-all {
  background-color: #fff;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 12px;

  .web-stories-title {
    font-size: 24px !important;
    font-weight: 700 !important;
    padding: 8px 12px;
  }

  .story-loader-component {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 630px;

    @media (max-width: 500px) {
      min-height: 500px;
    }
  }
}
