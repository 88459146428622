.footer-dynamic-div {
  width: 100%;
  margin: 0 auto 24px;
  overflow: hidden;
  border: 2px solid #1f1f1f;
  h2.wp-block-heading {
    background: #1f1f1f;
    color: white;
    padding: 8px;
    text-align: center;
    @media (max-width: 600px) {
      padding: 4px;
    }
  }

  .wp-block-image {
    width: 76%;
    margin: auto;
    text-align: center;
    img {
      width: 100%;
      border-radius: 8px;
    }
  }
  p {
    padding: 6px 12px;
    text-align: center;
    margin: 0;
    font-size: 17px;
    align-items: center;
    color: #444;
    font-weight: 400;
    line-height: 24px;
    @media (max-width: 600px) {
      padding: 4px;
    }
  }
}
