.monthly-page-warning-div {
  color: black;
  border: 2px solid;
  display: flex;
  align-items: center;
  border-color: #168d16;
  justify-content: space-between;
  padding: 6px 8px;
  cursor: pointer;

  .warning-div-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    p {
      font-weight: 600;
      opacity: 0.75;
    }
    .warn-icon {
      height: 25px;
    }
    .divider {
      min-height: 25px;
    }
  }
}
.hourly-weather-table-container {
  min-height: 200px;
  background-color: white;
  margin: 16px auto;
  .hourly-weather-table-heading {
    display: flex;
    padding: 16px 8px 8px 8px;
    align-items: center;
    justify-content: space-between;
    .select-box-div {
      display: flex;
      gap: 24px;
      align-items: center;
      cursor: pointer;
      .MuiSelect-select {
        padding: 10.5px 14px !important;
      }
    }
    .day-navigate {
      display: flex;
      @media (max-width: 724px) {
        display: none;
      }
    }
    .filter-btn-month {
      border-color: #1f1f1f !important;
      color: #1f1f1f !important;
    }
  }
  .gap-5 {
    gap: 5px !important;
  }
  .hourly-table-content {
    justify-content: space-around;
    padding: 0px 20px;
  }
  .padding-y-8 {
    padding: 8px 20px !important;
  }
}
