.previous-btn-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 6px 12px;
  border-radius: 4px;
  margin-bottom: 6px;
  cursor: pointer;
  animation: zoom-in 0.5s, fadeIn 0.6s;
  p {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
  }
}
@keyframes zoom-in {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: none;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
