.blog-home-common-card {
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 12px;

  @media (max-width: 600px) {
    padding: 12px;
  }

  h2 {
    font-size: 22px;
    font-weight: 700;
  }
  p {
    font-size: 14px;
    opacity: 0.7;
  }
}

.d-flex-align-center {
  display: flex;
  align-items: center;
}
.space-btn {
  justify-content: space-between !important;
}
.gap-6 {
  gap: 6px !important;
}
.cursor-pointer {
  cursor: pointer;
}
.blog-card-container {
  max-height: 318px;
  min-height: 318px;
  background-color: #f1f4f8;
  border-radius: 8px;
  min-width: 250px;
  max-width: 250px;
  margin: 8px;
  cursor: pointer;

  @media (max-width: 500px) {
    max-width: initial;
  }
  @media (max-width: 600px) {
    margin: 5px auto;
  }
  @media (min-width: 600px) and (max-width: 674px) {
    min-width: 165px;
    max-width: 165px;
  }
  @media (min-width: 674px) and (max-width: 750px) {
    min-width: 180px;
    max-width: 180px;
  }
  @media (min-width: 750px) and (max-width: 900px) {
    min-width: 214px;
    max-width: 214px;
  }
  img {
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    object-fit: cover;
    min-width: 100%;
    max-width: 100%;
    height: 170px;
    object-fit: contain;
  }
  .title {
    padding: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 166px;
    max-height: 65px;
    min-height: 65px;
  }
  .time {
    padding: 16px;
    color: #666c72;
    font-size: 12px;
    left: 16px;
    line-height: 1.33;
  }
}

.card-main-div {
  margin: 16px 0 !important;
  justify-content: flex-start;
}

.city-name-card {
  background: #e4eaf2;
  margin: 4px;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(50% - 8px);
  transition: all 0.7s linear;
  cursor: pointer;
  @media (max-width: 768px) {
    width: calc(100% - 8px);
    transition: all 0.7s linear;
  }

  p {
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
  }

  .city-name-card-temp {
    display: flex;
    align-items: center;
    width: 40%;
    justify-content: space-around;
  }
}
.city-card-container {
  margin: 16px 0;
  transition: max-height 0.5s ease-in-out;
  @media (max-width: 768px) {
    max-height: 216px;
    overflow: hidden;
    transition: all 0.7s ease-in-out;
  }
}
.see-more-cities {
  width: 98px !important;
  display: none;
  transition: all 1s ease-in-out;
  @media (max-width: 768px) {
    display: flex;
    transition: all 1s ease-in-out;
  }

  .see-bottom-arrow {
    transform: rotate(90deg);
    transition: transform 0.5s ease-in-out; 

  }
}
