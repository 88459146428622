.view-link-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 10px 0 8px;
  cursor: pointer;
}
.link {
  color: #000 !important;
  font-size: 16px;
  font-weight: 700 !important;
  text-transform: uppercase;
  cursor: pointer;
}
.label {
  color: #000;
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: normal;
  letter-spacing: 1px !important;
}
.previous-btn-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  animation: zoom-in 0.5s, fadeIn 0.6s;
  p {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
  }
}
