.hourly-card-container {
  background-color: white;
  border-radius: 8px;
  padding: 8px 16px;
  margin: 16px 0;
  .hourly-card-title {
    cursor: pointer;
    .hourly-card-title-1 {
      gap: 16px;
    }
    .hourly-card-img {
      margin-right: 8px;
    }

    .ten-day-weather-container-bottom-container-div-five {
      p {
        align-items: center;
        gap: 5px;
        display: flex;
        font-size: 16px;
        justify-content: flex-end;
      }
    }
  }
  .daily-weather-card-date {
    color: rgba(0, 0, 0, 0.6);

    .p-1 {
      color: #000;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
    }
    .p-2 {
      font-size: 12px;
    }
  }
  .ten-day-weather-container-bottom-container-div-two {
    text-align: center;
    .p-1 {
      color: #000;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  .ten-day-weather-container-bottom-container-div-three {
    h3 {
      font-size: 3.5rem;
    }
    p {
      align-self: end;
      margin-bottom: 10px;
      margin-left: -2px;
      font-size: 20px;
      color: #878787;
    }
  }
  .tab-title-rain-possibility {
    color: #878787;
    font-weight: 700;
    display: flex;
    gap: 4px;
    opacity: 0.9;
    white-space: nowrap;
    justify-content: center;
    .arrow-icon-right-daily-weather-card {
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      cursor: pointer;
    }
  }

  &:hover {
    .arrow-icon-right-daily-weather-card {
      opacity: 1;
      color: #000;
      transition: opacity 0.5s ease-in-out;
    }
  }
}
