.blog-sidebar-container {
  .blog-sidebar-container-1 {
    cursor: pointer;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 40px;
    .blog-sidebar-heading {
      padding: 12px;
      font-size: 22px;
      font-weight: 700;
    }
    .blog-sidebar-more-story {
      color: #000;
      display: block;
      font-size: 14px;
      font-weight: 700;
      padding: 12px;
      cursor: pointer;
    }
    .blog-data-grid {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 2px;
      padding: 8px;
      cursor: pointer;
      .blog-data {
        .blog-data-category {
          color: #666c72;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 0.42px;
          line-height: 1.6;
          margin-bottom: 4px;
        }
        .blog-data-time {
          font-size: 16px;
          font-weight: 500;
          line-height: 1.25;
          margin-bottom: 4px;
        }
        .blog-data-post-time {
          color: #666c72;
          font-size: 12px;
        }
      }
      .blog-data-img {
        img {
          border-radius: 6px;
          flex-shrink: 0;
          height: 100%;
          width: 100%;
          min-width: 64px;
          max-width: 64px;
          min-height: 64px;
          max-height: 64px;
        }
      }
    }
  }
  .blog-sidebar-container-2 {
  }
}

.slider-blog-date-card {
  display: flex;
  flex-direction: column !important;
  gap: 6px;
  margin: 5px 10px;
  min-width: 300px;
  max-width: 300px;
  cursor: pointer;
  .slider-blog-detail {
    text-align: center;
    padding: 0 8px;
    .category {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 5px;
    }

    .time {
      font-size: 12px;
      opacity: 0.5;
    }
  }
  .slider-blog-img {
    border-radius: 8px;
    height: 224px;
    width: 300px;
  }
}
.slick-next:before,
.slick-prev:before {
  color: #1f1f1f !important;
  font-size: 35px !important;
}

.slider-container {
  .slick-slide.slick-active {
    text-align: center !important;
  }
  .slick-slider {
    max-width: 95%;
  }
  .slick-list {
    margin: 0 4px 0 12px !important;
  }
}
