.header-box {
  flex-grow: 1;
  position: sticky;
  top: 0;
  z-index: 10;
}
.search-stack-div {
  background: white !important;
  border-radius: 8px !important;
  width: 90% !important;
  position: relative !important;

  .MuiOutlinedInput-root {
    padding: 2px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
    &:hover {
      border-color: transparent !important;
    }
  }
  .MuiAutocomplete-root .MuiAutocomplete-input {
    padding: 7.5px 30px 7.5px 10px !important;
  }
}

.web-screen-search {
  @media (max-width: 600px) {
    display: none !important;
  }
}
.tablet-screen-search {
  margin: 6px auto !important;
  display: none !important;
  @media (max-width: 600px) {
    display: block !important;
  }
}

.header-class {
  background-color: #1f1f1f !important;
  border-bottom: 2px solid #1f1f1f !important;
  box-shadow: none !important;
  transition: background-color 0.3s linear !important;
}
.header-class-home {
  background-color: transparent !important;
  border-bottom: 2px solid transparent !important;
  box-shadow: none !important;
  transition: background-color 0.3s linear !important;
}
.app-header-homepage {
  margin-top: -66px;
  padding-top: 80px;
  min-height: 324px;
  width: 100%;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column !important;

  align-items: center;
  gap: 16px;
  // background-size: cover;
  // background-position: bottom;
  .max-w-50 {
    max-width: 50%;
    width: 100%;

    @media (max-width: 600px) {
      max-width: 90%;
    }
  }
  .header-search-icon {
    position: absolute;
    right: 2%;
    top: 5px;
    opacity: 0.4;
    font-size: 32px;
  }
  .search-header-homepage {
    display: flex;
    align-items: center;
    justify-self: center;
    // min-width: 500px;
    gap: 8px;
    max-width: 100%;
    width: 100%;

    // @media only screen and (max-width: 675px) {
    //   min-width: 250px;
    // }

    .header-location-btn {
      color: white;
      padding: 6px;
      border: 1px solid white;
      border-radius: 30px;
      cursor: pointer;
      transition: color 0.7s ease-in-out, background 0.7s ease-in-out;
      &:hover {
        color: black;
        background: white;
        transition: color 0.7s ease-in-out, background 0.7s ease-in-out;
      }
    }
    input {
      padding-left: 12px !important;
    }
  }
  .search-hide-hover:hover {
    border: none !important;
  }
  .search-hide-hover:hover,
  .search-hide-hover:focus,
  .search-hide-hover:focus-visible,
  .search-hide-hover:focus-within,
  .search-hide-hover:active {
    .header-search-icon {
      display: none;
    }
  }
  .recent-location-container {
    backdrop-filter: blur(8px);
    background: rgba(0, 0, 0, 0.5);
    padding: 12px;
    border-radius: 8px;
    margin: 6px 0;
    color: white;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 160px;

    .city {
      font-size: 18px;
      line-height: 1.22;
      margin-right: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .country {
      font-size: 12px;
      line-height: 1.33;
      overflow: hidden;
      padding-bottom: 6px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .temp {
      font-size: 36px;
    }
    .temp-c {
      font-size: 17px;
      margin-left: -13px;
      opacity: 0.7;
      margin-top: 13px;
    }
    .card-recent-typo {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.container-class {
  max-width: 1290px !important;
  padding-inline: 0px !important;
}

.header-search {
  border: 1px solid #fff !important;
  border-radius: 8px !important;
  color: #fff !important;
}
.search-hide-hover {
  .MuiInputBase-input
    .MuiOutlinedInput-input
    .MuiInputBase-inputTypeSearch
    .MuiAutocomplete-input
    .MuiAutocomplete-inputFocused {
    &:focus-visible,
    &:hover {
      border-color: transparent !important;
    }
  }

  .MuiInputBase-formControl {
    fieldset {
      border-color: transparent !important;
    }
    &:hover {
      fieldset {
        border-color: transparent !important;
      }
    }
  }
  .MuiAutocomplete-popper {
    @media (max-width: 599px) {
      min-width: 60%;
    }
  }
  @media (min-width: 1200px) {
    .MuiInputBase-input {
      width: 60ch !important;
    }
    .MuiInputBase-input:focus {
      width: 60ch !important;
    }
  }
  @media only screen and (min-width: 945px) and (max-width: 1260px) {
    .MuiInputBase-input {
      width: 40ch !important;
    }
    .MuiInputBase-input:focus {
      width: 40ch !important;
    }
  }
  @media only screen and (min-width: 600px) and (max-width: 945px) {
    .MuiInputBase-input {
      width: 20ch !important;
    }
    .MuiInputBase-input:focus {
      width: 20ch !important;
    }
  }
}
.header-mainbar {
  gap: 30px;
  @media (max-width: 440px) {
    gap: 10px !important;
  }
  @media (max-width: 600px) {
    justify-content: space-between !important;
  }

  .header-city-name {
    display: flex;
    align-items: center;
    gap: 6px;
    // min-width: 207px;
    cursor: pointer;
    .city-name-header-main {
      width: 120px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      @media (max-width: 600px) {
        width: 80px;
      }
    }
  }
}

.header-logo-web {
  cursor: pointer;
  height: 50px;
  border-radius: 5px;

  // @media (max-width: 599px) {
  //   display: none;
  // }
}
.header-logo-web-home {
  cursor: pointer;
  height: 50px;
  border-radius: 5px;
}

.recent-location-title {
  font-size: 18px !important;
  margin-top: 16px !important;
  color: #1f1f1f;
}
.recent-search-location-list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: flex-start;
  margin-bottom: 16px;
  @media (max-width: 600px) {
    gap: 0;
    justify-content: space-evenly;
  }
}

.recent-card-3 {
  display: block;
  @media (max-width: 1007px) {
    display: none;
  }
}
.recent-card-4 {
  display: block;
  @media (max-width: 1351px) {
    display: none;
  }
}
.recent-card-5 {
  display: block;
  @media (max-width: 1721px) {
    display: none;
  }
}
