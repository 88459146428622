.date-wise-container {
  .date-wise-data-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    border-bottom: 1px solid #ccc;
    margin-bottom: 8px;
  }
  .cursor-pointer {
    cursor: pointer !important;
  }
}
.display-flex {
  display: flex;
  align-items: center;
  gap: 14px;
}
.date-wise-weather-container {
  background: #fff;
  border-radius: 8px;
  margin: 18px auto;
  .no-data-found-text {
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 600;
    color: red;
    margin-top: 14px;
  }
  .date-wise-weather-container-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px;

    h2 {
      align-self: baseline;
      color: #000;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.43;
      margin-right: 12px;
      min-width: 40px;
      text-transform: capitalize;
    }
    p {
      align-self: first baseline;
      font-size: 14px;
      font-weight: 500;
      text-align: right;
    }
  }
  .date-wise-weather-container-bottom {
    padding: 16px;
    .date-wise-temp {
      color: #000;
      font-size: 48px;
      font-weight: 500;
      line-height: 1.08;
      margin-right: 50px;
      white-space: nowrap;
    }
    .date-wise-description {
      font-size: 18px;
      padding-top: 11px;
    }
  }
}
.moon-sun-weather-container {
  .moon-sun-weather-container-top {
    p {
      align-self: first baseline;
      font-size: 14px;
      font-weight: 500;
      text-align: right;
    }
  }

  .temp-history-table-cell {
    display: flex;
    align-items: end;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    .temp-history-table-cell-label {
      opacity: 0.6;
      margin-left: 0.5;
    }
  }
  .temp-history-table-high-low {
    min-width: 150px;
    display: flex;
    align-items: end;
    justify-content: space-between;
    gap: 40px;
    padding: 8px 0;
  }
}
