.today-weather-container {
  cursor: pointer;
  background: #fff;
  border-radius: 8px;

  .today-weather-container-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px;

    h2 {
      font-size: 12px;
      color: #565656;
      font-weight: 600;
    }
    p {
      color: #000;
      letter-spacing: 0.5px;
      font-size: 12px;
      font-weight: 600;
    }
  }
  .today-weather-container-bottom {
    padding: 16px;
    div {
      display: flex;
      padding: 8px 14px;
      gap: 8px;
      align-items: center;
      p {
        font-size: 20px;
        color: #444;
        b {
          color: black;
        }
      }
    }
  }
}

.current-weather-container {
  cursor: pointer;
  background: #fff;
  border-radius: 8px;
  margin: 18px auto;

  .current-weather-container-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px;

    h2 {
      font-size: 12px;
      color: #565656;
      font-weight: 600;
    }
    p {
      color: #000;
      letter-spacing: 0.5px;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .current-weather-container-bottom {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    .current-weather-container-bottom-1 {
      .current-weather-container-bottom-1-1 {
        display: flex;
        align-items: center;
        gap: 20px;
      }
      h3 {
        font-size: 84px;
        font-weight: 700;
        letter-spacing: -0.7px;
        span {
          font-size: 30px;
          color: #888;
          margin-left: -27px;
        }
      }
      p {
        font-size: 20px;
      }
      .current-weather-bottom-content {
        margin-top: 15px;
        .current-weather-status {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 8px;
          max-width: auto;
          @media (max-width: 835px) {
            max-width: 200px;
          }
        }
        .current-weather-more-detail {
          font-size: 12px;
          font-weight: 700;
          line-height: 1.25;
          margin-right: 6px;
          img {
            transform: rotate(-90deg);
          }
        }

        .wind-mill-div {
          position: relative;
          .wind-mill-top-1-1 {
            position: absolute;
            height: 150px;
            top: -104px;
            right: 40px;

            @media (max-width: 500px) {
              right: 0px;
            }
          }
        }
      }
    }
    .current-weather-bottom-right {
      width: 50%;
      .current-weather-bottom-right-content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 12px 0px;
        .current-weather-label {
          font-size: 16px;
          font-weight: 400;
          margin-right: 8px;
        }
        .current-weather-value {
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
  }
}

.hourly-weather-container {
  cursor: pointer;
  background: #fff;
  border-radius: 8px;
  margin: 18px auto;

  .hourly-weather-container-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px;

    h2 {
      font-size: 12px;
      color: #565656;
      font-weight: 600;
    }
    p {
      color: #000;
      letter-spacing: 0.5px;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .hourly-weather-container-bottom {
    .MuiTabs-scrollButtons {
      background: #b7c1cc !important;
      color: white !important;
    }
    .Mui-disabled {
      cursor: not-allowed !important;
    }
    .tabbing-tab-hourly {
      &:hover {
        background: #f1f4f8 !important;
      }
      .tab-title-label {
        color: #000;

        .tab-title-time {
          font-weight: 700;
          font-size: 16px;
        }
        .tab-title-temp {
          font-size: 32px;
          font-weight: 700;
          margin-left: 5px;
        }
        .tab-title-rain-possibility {
          color: #000;
          justify-content: center;
          display: flex;
          gap: 4px;
          opacity: 0.6;
          white-space: nowrap;
        }
      }
    }
  }
}

.ten-day-weather-container {
  background: #fff;
  border-radius: 8px;
  margin: 18px auto;

  .ten-day-weather-container-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px;

    h2 {
      font-size: 12px;
      color: #565656;
      font-weight: 600;
    }
    p {
      color: #000;
      letter-spacing: 0.5px;
      font-size: 12px;
      font-weight: 600;
    }
  }
  .ten-day-weather-container-bottom {
    .ten-day-weather-container-bottom-container {
      padding: 15px 20px;
      color: rgba(0, 0, 0, 0.6);
      cursor: pointer;
      &:hover {
        background: #f1f4f8 !important;
      }
      .ten-day-weather-container-bottom-container-div-one {
        .p-1 {
          color: #000;
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
        }
        .p-2 {
          font-size: 12px;
        }
      }
      .ten-day-weather-container-bottom-container-div-two {
        text-align: center;
      }
      .ten-day-weather-container-bottom-container-div-three {
        min-width: 180px;
        gap: 5px;
        justify-content: flex-start;
        h3 {
          color: #000;
          flex-shrink: 0;
          font-size: 28px;
          font-weight: 700;
          margin-right: 4px;
        }

        span {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.6);
          font-weight: 100;
        }
      }
      .ten-day-weather-container-bottom-container-div-four {
        min-width: 175px;
        .p-1 {
          color: #000;
          font-size: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .p-2 {
          -webkit-box-align: center;
          -ms-flex-align: center;
          font-size: 16px;

          align-items: center;
          color: rgba(0, 0, 0, 0.6);
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          font-weight: 400;
        }
      }
      .ten-day-weather-container-bottom-container-div-five {
        p {
          align-items: center;
          gap: 5px;
          display: flex;
          font-size: 16px;
        }
      }
    }
  }
}

.display-flex-all-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.gap-5 {
  gap: 5px;
}
.gap-10 {
  gap: 10px;
}
.moon-sun-weather-container {
  background: #fff;
  border-radius: 8px;
  margin: 18px auto;

  .moon-sun-weather-container-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px;

    h2 {
      font-size: 12px;
      color: #565656;
      font-weight: 600;
    }
    p {
      color: #000;
      letter-spacing: 0.5px;
      font-size: 12px;
      font-weight: 600;
    }
  }
  .moon-sun-weather-container-bottom {
    padding: 0 16px;
    .moon-sun-weather-container-bottom-1 {
      padding: 10px 0;
      .moon-sun-weather-container-bottom-1-1 {
        gap: 14px;
      }
      .moon-sun-weather-container-bottom-1-2 {
        gap: 30px;
        .p-2 {
          min-width: 70px;
        }
        .moon-sun-weather-container-bottom-1-2-1 {
          .p-1 {
            font-size: 0.75rem;
            margin-left: auto;
            opacity: 0.5;
            span {
              font-size: 1rem;
              min-width: 68px;
              white-space: nowrap;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.air-quality-container {
  background: #fff;
  border-radius: 8px;
  margin: 18px auto;
  cursor: pointer;

  .air-quality-container-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px;

    h2 {
      font-size: 12px;
      color: #565656;
      font-weight: 600;
    }
    p {
      color: #000;
      letter-spacing: 0.5px;
      font-size: 12px;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .air-quality-container-bottom {
    padding: 20px;
    .bottom-content {
      margin-bottom: 8px;
      padding: 8px 10px 8px 0px;
      .air-quality-status {
        border-right: 4px solid;
        padding: 0 12px 0 0;
        font-size: 18px;
      }
    }
    .air-quality-desc {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
