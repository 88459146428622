.accordion-row {
  display: flex;
  gap: 18px;
  border-bottom: 1px solid #ccc;
  align-items: center;
  padding: 8px 3px;

  .accordion-row-time {
    font-weight: 600;
    font-size: 14px;
  }
  .accordion-row-status {
    font-size: 14px;
  }
}
.accordion-detail-first-grid {
  border-top: 1px solid #ccc;
}
.accordion-title {
  font-weight: 700;
  min-height: 82px !important;
}
.accordion-title-div {
  display: flex;
  align-items: center;

  .MuiAccordionDetails-root {
    padding: 8px 16px 8px !important;
  }
}
