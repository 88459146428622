.blog-category-index-0-card {
  display: flex;
  margin: 12px 0;
  cursor: pointer;
  img {
    width: 100%;
    max-width: 50%;
    min-height: 200px;
    height: 100%;
    max-height: 240px;
    min-height: 240px;
    border-radius: 5px 0 0 5px;
  }
  .blog-category-index-0-detail {
    background-color: white;
    display: flex;
    flex-direction: column !important;
    align-items: flex-start;
    padding: 20px;
    justify-content: space-between;
    width: 100%;
    max-height: 240px;
    overflow: hidden;
    border-radius: 0 5px 5px 0;

    .title {
      font-size: 22px;
      font-weight: 700;
      opacity: 0.75;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .time {
      font-size: 14px;
      opacity: 0.6;
    }
  }
}

.blog-category-index-1-2-card {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2px;
  padding: 8px;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
  .blog-data {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;

    .blog-data-time {
      font-size: 16px;
      font-weight: 700;
      opacity: 0.75;
    }
    .blog-data-post-time {
      color: #666c72;
      font-size: 12px;
    }
  }
  .blog-data-img {
    img {
      border-radius: 6px;
      flex-shrink: 0;
      height: 100%;
      width: 100%;
      min-width: 75px;
      max-width: 75px;
      min-height: 75px;
      max-height: 75px;
    }
  }
}

.blog-view-index-3-4 {
  background: white;
  border-radius: 6px;
  cursor: pointer;
  img {
    border-radius: 6px 6px 0 0;
    flex-shrink: 0;
    height: 100%;
    width: 100%;
    max-height: 240px;
    min-height: 240px;
  }
  .blog-data {
    min-height: 120px;
    display: flex;
    align-items: flex-start;
    flex-direction: column !important;
    justify-content: space-between;
    padding: 14px;
    .blog-data-time {
      font-size: 16px;
      font-weight: 700;
      opacity: 0.75;
    }
    .blog-data-post-time {
      color: #666c72;
      font-size: 12px;
    }
  }
}

.blog-category-index-5-8-card {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2px;
  padding: 8px;
  background-color: transparent;
  max-height: 135px;
  cursor: pointer;
  transition: background-color 0.5s ease-in-out;
  border-radius: 5px;
  &:hover {
    background-color: white;
    transition: background-color 0.5s ease-in-out;
  }
  .blog-data {
    .blog-data-time {
      font-size: 16px;
      font-weight: 700;
      opacity: 0.75;
    }
    .blog-data-post-time {
      color: #666c72;
      font-size: 12px;
    }
  }
  .blog-data-img {
    img {
      border-radius: 6px;
      flex-shrink: 0;
      height: 100%;
      width: 100%;
      min-width: 116px;
      max-width: 116px;
      min-height: 116px;
      max-height: 116px;
    }
  }
}

.blog-category-pagination {
  margin: 12px 0 8px 0 !important;
  nav {
    ul {
      justify-content: space-around;
    }
  }

  .Mui-selected {
    background-color: #1f1f1f !important;
    color: white !important;
  }
}

.loader-parent-div {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 250px;
  width: 100%;
}
