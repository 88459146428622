.input-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;

  @media (max-width: 624px) {
    display: block;
  }

  .input-label {
    color: #272727;
    font-weight: 600;
    font-size: 18px;
  }

  .common-input-parent {
    input {
      outline: none;
      border: none;
      border-radius: 5px;
      background-color: #e4eaf2;
      max-height: 48px;
      color: black;
      -moz-appearance: "textfield" !important;
      appearance: "textfield" !important;
      padding: 12px 8px 12px 24px;
      text-align: start;
      font-size: 20px;

      @media (max-width: 486px) {
        width: 100%;
      }
    }
    textarea {
      outline: none;
      border: none;
      border-radius: 5px;
      background-color: #e4eaf2;
      color: black;
      -moz-appearance: "textfield" !important;
      appearance: "textfield" !important;
      padding: 12px 8px 12px 34px;
      text-align: start;
      font-size: 20px;
      width: 100%;

      @media (max-width: 486px) {
        width: 100%;
      }
    }

    @media (max-width: 624px) {
      width: 75%;
    }
  }

  .label-value {
    color: #686868;
    font-size: 18px;
    min-width: fit-content;
  }
}
