.contact-us-component {
  text-align: center;
  .input-detail-div {
    text-align: left;
    border-radius: 5px;
    padding: 20px;
    background: white;

    .input-div {
      display: block;
      .common-input-parent {
        width: 100%;
        input {
          width: 100%;
        }
      }
    }
  }
  button {
    margin-top: 16px;
    background-color: #1f1f1f;
    width: 50%;
    &:hover,
    &:focus {
      background-color: #1f1f1f;
    }
  }
}
.back-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0 10px 0px !important;
  gap: 10px;
  letter-spacing: 1px;
  font-size: 22px;
  font-weight: 700 !important;
}
