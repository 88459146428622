.table-date-container {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  padding: 18px;
  &:hover {
    background: #eee;
  }

  .temp-2 {
    color: #878787;
  }
}
.present-class {
  color: white;
  background: black;
  border-radius: 50px;
  padding: 4px;
  width: 21px;
  height: 22px;
  text-align: center;
}
.future-date-class {
  font-size: 14px;
  color: black;
  min-height: 28px;
}
.past-date-class {
  font-size: 14px;
  color: #878787;
}
