.App {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #e4eaf2;
  .App-div-1 {
    background: #e4eaf2;
  }
}

a {
  cursor: pointer;
  text-decoration: none;
  color: white;
}
a:hover {
  text-decoration: underline;
}
.table-data-error {
  text-align: center !important;
  font-size: 20px !important;
  color: red !important;
}

.calculation-part {
  background-color: #f9feff;
  padding: 8px;
  border-radius: 8px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3));
  animation: zoom-in 0.5s, fadeIn 0.6s;
}
@keyframes zoom-in {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: none;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.home-card {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 0px 20px;
  align-items: center;

  @media (max-width: 600px) {
    padding: 0px 6px;
  }
}

.heading-calcs {
  margin-left: 16px;
  margin-top: 5px;
  height: 28px;
}
.search-not-found {
  color: #61b0c1 !important;
  font-size: 34px !important;
  font-weight: 600 !important;
  letter-spacing: normal !important;
  line-height: normal !important;
  span {
    color: #295fa6;
  }
}
.switch-btn-app {
  background: white;
  padding: 7px;
  border-radius: 6px;
  position: fixed;
  bottom: 12px;
  right: 4px; 
  border: 1px solid #aaa;
  z-index: 23;
  
}
.tabbing-parent-div {
  .MuiTabs-flexContainer {
    justify-content: space-between;
  }
  .Mui-selected {
    color: #1f1f1f !important;
  }
  .MuiTabs-indicator {
    background: #1f1f1f !important;
  }
}

.small-loader-component-div {
  text-align: center;
  margin: auto;
  width: 100%;
  margin-top: 12px;
}

.animation-zoom-in {
  animation: zoom-in 0.5s, fadeIn 0.6s;
}
@keyframes zoom-in {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: none;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.app-bottom-slider-outer-div {
  max-width: calc(100% - 40px);
  margin: 16px auto;
  width: 100%;
  padding-bottom: 18px;
}

.divider-black {
  color: black;
}
.top-keywords {
  max-width: 66% !important;
  margin: auto !important;
}
.mt-minus-130{
  margin-top: -130px !important;
}
.keywords-div-hindi {
  display: flex;
  color: #1f1f1f;
  gap: 14px;
  margin: 12px 0;
  padding: 8px 16px;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    gap: 6px;
    padding: 0;
    max-width: 100% !important;
  }
  .keyword-card {
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    background: #1f1f1f;
    color: white;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: #1f1f1f;
      background: white;
      transition: all 0.3s ease-in-out;
    }
  }
}

.google-translate-btn {
  .skiptranslate {
    position: fixed;
    bottom: 0px;
    z-index: 23;
    right: 125px;
    background: transparent;
    
    .goog-te-combo {
      border: 1px solid #aaa !important;
      border-radius: 5px !important;
      color: #747575 !important;
      background: #fff !important;
      outline: none;
      padding: 5px;
    }
  }
}
.skiptranslate {
  iframe {
    display: none !important;
  }
}
#goog-gt-tt div:first-child,
#goog-gt-tt {
  display: none !important;
}

body {
  top: 0px !important;
}
