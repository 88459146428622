.loader {
  width: 108px;
  height: 60px;
  color: #269af2;
  --c: radial-gradient(farthest-side, currentColor 96%, #0000);
  background: var(--c) 100% 100% /30% 60%, var(--c) 70% 0 /50% 100%,
    var(--c) 0 100% /36% 68%, var(--c) 27% 18% /26% 40%,
    linear-gradient(currentColor 0 0) bottom/67% 58%;
  background-repeat: no-repeat;
  position: relative;
}
.loader:after {
  content: "";
  position: absolute;
  inset: 0;
  background: inherit;
  opacity: 0.4;
  animation: l7 1s infinite;
}
@keyframes l7 {
  to {
    transform: scale(1.8);
    opacity: 0;
  }
}

.loader-p {
  --w: 10ch;
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  line-height: 1.2em;
  letter-spacing: var(--w);
  width: var(--w);
  overflow: hidden;
  white-space: nowrap;
  color: #0000;
  animation: l17 2s infinite;
}
.loader-p:before {
  content: "Loading...";
}

@keyframes l17 {
  0% {
    text-shadow: calc(0 * var(--w)) -1.2em #000, calc(-1 * var(--w)) -1.2em #000,
      calc(-2 * var(--w)) -1.2em #000, calc(-3 * var(--w)) -1.2em #000,
      calc(-4 * var(--w)) -1.2em #000, calc(-5 * var(--w)) -1.2em #000,
      calc(-6 * var(--w)) -1.2em #000, calc(-7 * var(--w)) -1.2em #000,
      calc(-8 * var(--w)) -1.2em #000, calc(-9 * var(--w)) -1.2em #000;
  }
  4% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) -1.2em #000,
      calc(-2 * var(--w)) -1.2em #000, calc(-3 * var(--w)) -1.2em #000,
      calc(-4 * var(--w)) -1.2em #000, calc(-5 * var(--w)) -1.2em #000,
      calc(-6 * var(--w)) -1.2em #000, calc(-7 * var(--w)) -1.2em #000,
      calc(-8 * var(--w)) -1.2em #000, calc(-9 * var(--w)) -1.2em #000;
  }
  8% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) -1.2em #000, calc(-3 * var(--w)) -1.2em #000,
      calc(-4 * var(--w)) -1.2em #000, calc(-5 * var(--w)) -1.2em #000,
      calc(-6 * var(--w)) -1.2em #000, calc(-7 * var(--w)) -1.2em #000,
      calc(-8 * var(--w)) -1.2em #000, calc(-9 * var(--w)) -1.2em #000;
  }
  12% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) -1.2em #000,
      calc(-4 * var(--w)) -1.2em #000, calc(-5 * var(--w)) -1.2em #000,
      calc(-6 * var(--w)) -1.2em #000, calc(-7 * var(--w)) -1.2em #000,
      calc(-8 * var(--w)) -1.2em #000, calc(-9 * var(--w)) -1.2em #000;
  }
  16% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) -1.2em #000, calc(-5 * var(--w)) -1.2em #000,
      calc(-6 * var(--w)) -1.2em #000, calc(-7 * var(--w)) -1.2em #000,
      calc(-8 * var(--w)) -1.2em #000, calc(-9 * var(--w)) -1.2em #000;
  }
  20% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) -1.2em #000,
      calc(-6 * var(--w)) -1.2em #000, calc(-7 * var(--w)) -1.2em #000,
      calc(-8 * var(--w)) -1.2em #000, calc(-9 * var(--w)) -1.2em #000;
  }
  24% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) -1.2em #000, calc(-7 * var(--w)) -1.2em #000,
      calc(-8 * var(--w)) -1.2em #000, calc(-9 * var(--w)) -1.2em #000;
  }
  28% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) -1.2em #000,
      calc(-8 * var(--w)) -1.2em #000, calc(-9 * var(--w)) -1.2em #000;
  }
  32% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) -1.2em #000, calc(-9 * var(--w)) -1.2em #000;
  }
  36% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) -1.2em #000;
  }
  40%,
  60% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 0 #000;
  }
  64% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 1.2em #000;
  }
  68% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) 1.2em #000, calc(-9 * var(--w)) 1.2em #000;
  }
  72% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 1.2em #000,
      calc(-8 * var(--w)) 1.2em #000, calc(-9 * var(--w)) 1.2em #000;
  }
  76% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 1.2em #000, calc(-7 * var(--w)) 1.2em #000,
      calc(-8 * var(--w)) 1.2em #000, calc(-9 * var(--w)) 1.2em #000;
  }
  80% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 1.2em #000,
      calc(-6 * var(--w)) 1.2em #000, calc(-7 * var(--w)) 1.2em #000,
      calc(-8 * var(--w)) 1.2em #000, calc(-9 * var(--w)) 1.2em #000;
  }
  84% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 1.2em #000, calc(-5 * var(--w)) 1.2em #000,
      calc(-6 * var(--w)) 1.2em #000, calc(-7 * var(--w)) 1.2em #000,
      calc(-8 * var(--w)) 1.2em #000, calc(-9 * var(--w)) 1.2em #000;
  }
  88% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 1.2em #000,
      calc(-4 * var(--w)) 1.2em #000, calc(-5 * var(--w)) 1.2em #000,
      calc(-6 * var(--w)) 1.2em #000, calc(-7 * var(--w)) 1.2em #000,
      calc(-8 * var(--w)) 1.2em #000, calc(-9 * var(--w)) 1.2em #000;
  }
  92% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 1.2em #000, calc(-3 * var(--w)) 1.2em #000,
      calc(-4 * var(--w)) 1.2em #000, calc(-5 * var(--w)) 1.2em #000,
      calc(-6 * var(--w)) 1.2em #000, calc(-7 * var(--w)) 1.2em #000,
      calc(-8 * var(--w)) 1.2em #000, calc(-9 * var(--w)) 1.2em #000;
  }
  96% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 1.2em #000,
      calc(-2 * var(--w)) 1.2em #000, calc(-3 * var(--w)) 1.2em #000,
      calc(-4 * var(--w)) 1.2em #000, calc(-5 * var(--w)) 1.2em #000,
      calc(-6 * var(--w)) 1.2em #000, calc(-7 * var(--w)) 1.2em #000,
      calc(-8 * var(--w)) 1.2em #000, calc(-9 * var(--w)) 1.2em #000;
  }
  100% {
    text-shadow: calc(0 * var(--w)) 1.2em #000, calc(-1 * var(--w)) 1.2em #000,
      calc(-2 * var(--w)) 1.2em #000, calc(-3 * var(--w)) 1.2em #000,
      calc(-4 * var(--w)) 1.2em #000, calc(-5 * var(--w)) 1.2em #000,
      calc(-6 * var(--w)) 1.2em #000, calc(-7 * var(--w)) 1.2em #000,
      calc(-8 * var(--w)) 1.2em #000, calc(-9 * var(--w)) 1.2em #000;
  }
}

.loader-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column !important;
  gap: 5px;
  min-height: 150px;
}
.small-loader-div {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 207px;
  .loader-small {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 4px solid;
    border-color: #fff #fff transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader-small::after,
  .loader-small::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid;
    border-color: transparent transparent #ff3d00 #ff3d00;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }
  .loader-small::before {
    width: 16px;
    height: 16px;
    border-color: #fff #fff transparent transparent;
    animation: rotation 1.5s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
}
